// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-page-component__host{display:flex;flex-direction:column}.news-page-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;min-height:95vh;gap:1rem}.news-page-component__subnav{order:-1}.news-page-component__newsFeedAndSponsorsCont{display:flex;gap:1rem;align-items:flex-start}.news-page-component__newsFeed{width:80%}.news-page-component__sponsors{width:20%;border-radius:.25rem}@media(max-width: 960px){.news-page-component__newsFeedAndSponsorsCont{flex-direction:column-reverse}.news-page-component__newsFeed{width:100%}.news-page-component__sponsors{width:100%}}`, "",{"version":3,"sources":["webpack://./news-page/news-page.component.scss"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,qBAAA,CAGJ,qCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,eAAA,CACA,QAAA,CAGJ,6BACI,QAAA,CAGJ,8CACI,YAAA,CACA,QAAA,CACA,sBAAA,CAGJ,+BACI,SAAA,CAGJ,+BACI,SAAA,CACA,oBAAA,CAGJ,yBACI,8CACI,6BAAA,CAGJ,+BACI,UAAA,CAGJ,+BACI,UAAA,CAAA","sourcesContent":[".host {\n    display: flex;\n    flex-direction: column;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    min-height: 95vh;\n    gap: 1rem;\n}\n\n.subnav {\n    order: -1;\n}\n\n.newsFeedAndSponsorsCont {\n    display: flex;\n    gap: 1rem;\n    align-items: flex-start;\n}\n\n.newsFeed {\n    width: 80%;\n}\n\n.sponsors {\n    width: 20%;\n    border-radius: 0.25rem;\n}\n\n@media (max-width: 960px) {\n    .newsFeedAndSponsorsCont {\n        flex-direction: column-reverse;\n    }\n\n    .newsFeed {\n        width: 100%;\n    }\n\n    .sponsors {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `news-page-component__host`,
	"paddingContent": `news-page-component__paddingContent`,
	"subnav": `news-page-component__subnav`,
	"newsFeedAndSponsorsCont": `news-page-component__newsFeedAndSponsorsCont`,
	"newsFeed": `news-page-component__newsFeed`,
	"sponsors": `news-page-component__sponsors`
};
module.exports = ___CSS_LOADER_EXPORT___;
