// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 960px){:root{--font-size-section-title: 1.25rem}}.team-component__teamLink{color:#257cff}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./shared/components/cell-renderers/team/team.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAyBJ,yBACI,MACI,mCAAA,CAAA,CAKR,+CACI,MACI,kCAAA,CAAA,CChCR,0BACI,aDGS","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n$secondary-text-color: rgb(0, 0, 0, 0.5);\n$gold-color: rgb(255, 215, 0);\n$silver-color: rgb(192, 192, 192);\n$bronze-color: rgb(205, 127, 50);\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n$font-size-placeholder: 2rem;\n\n$disabled-btn-bg: #f0f0f0;\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n$partially-visible-white-color: rgb(255, 255, 255, 0.6);\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 960px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.teamLink {\n    color: $link-color;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamLink": `team-component__teamLink`
};
module.exports = ___CSS_LOADER_EXPORT___;
