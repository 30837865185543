import { NewsDetailsPageComp } from './news-details-page.component';
import { NewsDetailsSectionComp } from './news-details-section/news-details-section.component';
import { LoaderComp } from '@Components/loader/loader.component';
import { EditorJSComp } from '@Components/editorjs/editorjs.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-news-details-page', comp: NewsDetailsPageComp },
    { tag: 'app-news-details-section', comp: NewsDetailsSectionComp },
    { tag: 'app-loader', comp: LoaderComp },
    { tag: 'app-editorjs', comp: EditorJSComp }
];
