import css from './tournament-overlay.component.scss';
export class TournamentOverlayComp extends HTMLElement {
    tournamentStats;
    set tournamentStatsSetter(tournamentStats) {
        this.tournamentStats = tournamentStats;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.setHostFocus();
        this.escBtnListener();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader());
        if (!this.tournamentStats?.type) {
            // show empty placeholder
        }
        else {
            if (this.tournamentStats.type === 'group') {
                this.tournamentStats.groups.forEach(group => {
                    if (group.type === 'round') {
                        this.append(this.mkOverallStanding(group));
                    }
                    else {
                        this.append(this.mkPlayoffSection(group));
                    }
                });
            }
        }
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('header');
        header.classList.add(css.header);
        header.append(this.mkCloseBtn());
        return header;
    }
    mkOverallStanding(group) {
        const overallStanding = document.createElement('app-overall-standing');
        overallStanding.sectionTitleSetter = group.name;
        overallStanding.overallStandingSetter = group.overallStanding;
        return overallStanding;
    }
    mkPlayoffSection(group) {
        const section = document.createElement('app-playoff-section');
        section.sectionTitleSetter = group.name;
        section.stagesSetter = group.stages;
        return section;
    }
    mkCloseBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.closeBtn);
        btn.append(this.mkCloseIcon());
        btn.addEventListener('click', () => this.closeOverlay());
        return btn;
    }
    mkCloseIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.closeIcon);
        return icon;
    }
    closeOverlay() {
        this.remove();
    }
    setHostFocus() {
        this.tabIndex = 1;
        this.focus();
    }
    escBtnListener() {
        this.addEventListener('keyup', (e) => {
            if (e.code === 'Escape')
                this.closeOverlay();
        });
    }
}
