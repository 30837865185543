// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-confrontation-component__cont{display:flex;gap:.75rem}.team-confrontation-component__team{display:flex;align-items:center;gap:.5rem}.team-confrontation-component__logo{width:2rem;height:2rem;border-radius:50%}`, "",{"version":3,"sources":["webpack://./shared/components/cell-renderers/team-confrontation/team-confrontation.component.scss"],"names":[],"mappings":"AAAA,oCACI,YAAA,CACA,UAAA,CAGJ,oCACI,YAAA,CACA,kBAAA,CACA,SAAA,CAGJ,oCACI,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".cont {\n    display: flex;\n    gap: 0.75rem;\n}\n\n.team {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.logo {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": `team-confrontation-component__cont`,
	"team": `team-confrontation-component__team`,
	"logo": `team-confrontation-component__logo`
};
module.exports = ___CSS_LOADER_EXPORT___;
