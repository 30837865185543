import css from './team.component.scss';
export class TeamCellRendererComp {
    gui;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        this.gui = this.mkTeam(params.value.teamName, params.value.teamId);
    }
    getGui() {
        return this.gui;
    }
    refresh(_) {
        return false;
    }
    mkTeam(teamName, teamId) {
        const name = document.createElement('a');
        name.classList.add(css.teamLink);
        name.href = teamId;
        name.textContent = teamName;
        return name;
    }
}
