import css from './admin-page.component.scss';
import { ADMIN_ROUTES, Router } from './admin-page.router';
export class AdminPageComp extends HTMLElement {
    template = {};
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.initRouting();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkAdminNav(), this.mkRouterOutlet());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkAdminNav() {
        const nav = document.createElement('app-admin-nav');
        return nav;
    }
    mkRouterOutlet() {
        const routerOutlet = document.createElement('div');
        routerOutlet.classList.add(css.routerOutlet);
        this.template.routerOutlet = routerOutlet;
        return routerOutlet;
    }
    initRouting() {
        const adminRouter = new Router(this.template.routerOutlet, ADMIN_ROUTES);
        this.addEventListener('click', (e) => {
            if (e.target.matches('[data-router-link]')) {
                e.preventDefault();
                e.stopPropagation();
                history.pushState(null, '', e.target.href);
                adminRouter.generateOutlet();
            }
        });
        adminRouter.generateOutlet();
    }
}
