// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 960px){:root{--font-size-section-title: 1.25rem}}.tabs-component__host{display:flex}.tabs-component__tab{outline:none;border:0;background-color:rgba(0,0,0,0);padding:1rem 2rem;cursor:pointer}.tabs-component__activeTab{border-bottom:2px solid #004352}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./admin-page/admin-tournaments/tournament-editor/tabs/tabs.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAyBJ,yBACI,MACI,mCAAA,CAAA,CAKR,+CACI,MACI,kCAAA,CAAA,CChCR,sBACI,YAAA,CAGJ,qBACI,YAAA,CACA,QAAA,CACA,8BAAA,CACA,iBAAA,CACA,cAAA,CAGJ,2BACI,+BAAA","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n$secondary-text-color: rgb(0, 0, 0, 0.5);\n$gold-color: rgb(255, 215, 0);\n$silver-color: rgb(192, 192, 192);\n$bronze-color: rgb(205, 127, 50);\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n$font-size-placeholder: 2rem;\n\n$disabled-btn-bg: #f0f0f0;\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n$partially-visible-white-color: rgb(255, 255, 255, 0.6);\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 960px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.host {\n    display: flex;\n}\n\n.tab {\n    outline: none;\n    border: 0;\n    background-color: transparent;\n    padding: 1rem 2rem;\n    cursor: pointer;\n}\n\n.activeTab {\n    border-bottom: 2px solid $main-bg-color;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `tabs-component__host`,
	"tab": `tabs-component__tab`,
	"activeTab": `tabs-component__activeTab`
};
module.exports = ___CSS_LOADER_EXPORT___;
