import css from './main-page.component.scss';
import { Subject } from 'rxjs';
import { PlayersHttp } from '@Services/http/players.http';
import { LeaderboardsHttp } from '@Services/http/leaderboards.http';
import { NewsHttp } from '@Services/http/news.http';
export class MainComponent extends HTMLElement {
    subscriptions = [];
    playersHttp = PlayersHttp.instance;
    leaderboardsHttp = LeaderboardsHttp.instance;
    newsHttp = NewsHttp.instance;
    newsData = new Subject();
    playersData = new Subject();
    leaderboardsData = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqIntroNews();
        this.reqPlayersInfo();
        this.reqLeaderboardsInfo();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqIntroNews() {
        this.newsHttp.getNewsByRange(6)
            .then(news => this.newsData.next(news))
            .catch();
    }
    reqPlayersInfo() {
        this.playersHttp.getPlayersByTodaysBirthday().then(players => this.playersData.next(players));
    }
    mkIntroSection() {
        const introSection = document.createElement('div');
        this.subscriptions.push(this.newsData.subscribe(news => {
            const intro = document.createElement('app-intro-section');
            intro.firstNewsSetter = news[0];
            intro.lastFiveNewsSetter = news.slice(1);
            introSection.append(intro);
        }));
        return introSection;
    }
    mkBirthdaySlider() {
        const birthdaySection = document.createElement('app-birthday-section');
        this.subscriptions.push(this.playersData.subscribe(players => {
            if (!players || !players?.length)
                return;
            birthdaySection.dataSetter = players;
        }));
        return birthdaySection;
    }
    reqLeaderboardsInfo() {
        this.leaderboardsHttp.getLeaderboards().then(leaderboards => this.leaderboardsData.next(leaderboards));
    }
    mkLiveVideoSlider() {
        const videoSection = document.createElement('app-video-section');
        //TODO убрать после подклбчения бека
        videoSection.dataSetter = [
            'https://vk.com/video_ext.php?oid=-215340637&id=456239183&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239182&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239181&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239180&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239179&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239178&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239177&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239176&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239175&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239174&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239143&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239142&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239141&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239140&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239139&hd=2&autoplay=0',
        ];
        return videoSection;
    }
    mkLeaderboardSlider() {
        const leaderboardSection = document.createElement('div');
        this.subscriptions.push(this.leaderboardsData.subscribe(leaderboards => {
            const leaderboard = document.createElement('app-leaderboard-section');
            leaderboard.dataSetter = leaderboards;
            leaderboardSection.append(leaderboard);
        }));
        return leaderboardSection;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkIntroSection(), this.mkLiveVideoSlider(), this.mkBirthdaySlider(), this.mkLeaderboardSlider());
        return paddingContent;
    }
}
