import css from './loader.component.scss';
export class LoaderComp extends HTMLElement {
    set widthSetter(widthPx) {
        this.style.width = `${widthPx}px`;
    }
    set heightSetter(heightPx) {
        this.style.height = `${heightPx}px`;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkLoader());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkLoader() {
        const loader = document.createElement('div');
        loader.classList.add(css.loader);
        return loader;
    }
}
