import css from './news-page.component.scss';
import bazilikLogo from '@Assets/logos/logo-bazilik.svg';
export class NewsComp extends HTMLElement {
    playersNewsSectionRef;
    tournamentsNewsSectionRef;
    latestNewsSectionRef;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkSubnav(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkPlayersNewsSection(), this.mkTournamentsNewsSection(), this.mkNewsFeedAndSponsors());
        return paddingContent;
    }
    mkNewsFeedAndSponsors() {
        const cont = document.createElement('div');
        cont.classList.add(css.newsFeedAndSponsorsCont);
        cont.append(this.mkNewsFeedSection(), this.mkSponsors());
        return cont;
    }
    mkSponsors() {
        const sponsors = document.createElement('app-sponsors');
        sponsors.classList.add(css.sponsors);
        sponsors.sponsorsImgSetter = [bazilikLogo, bazilikLogo, bazilikLogo];
        return sponsors;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPlayersNewsSection() {
        const section = document.createElement('app-news-slider-section');
        this.playersNewsSectionRef = section;
        section.headingSetter = 'Игроки';
        section.newsCategorySetter = 'players';
        return section;
    }
    mkTournamentsNewsSection() {
        const section = document.createElement('app-news-slider-section');
        this.tournamentsNewsSectionRef = section;
        section.headingSetter = 'Турниры';
        section.newsCategorySetter = 'tournaments';
        return section;
    }
    mkNewsFeedSection() {
        const section = document.createElement('app-news-feed-section');
        this.latestNewsSectionRef = section;
        section.classList.add(css.newsFeed);
        return section;
    }
    mkSubnav() {
        const subnav = document.createElement('app-subnav');
        subnav.classList.add(css.subnav);
        subnav.subnavSetter = {
            heading: 'Новости',
            links: [
                {
                    name: 'игроки',
                    elRef: this.playersNewsSectionRef
                },
                {
                    name: 'турниры',
                    elRef: this.tournamentsNewsSectionRef
                },
                {
                    name: 'последнее',
                    elRef: this.latestNewsSectionRef
                }
            ]
        };
        return subnav;
    }
}
