import { TournamentsComp } from './tournaments-page.component';
import { TournamentCardComp } from '@Components/tournament-card/tournament-card.component';
import { LoaderComp } from '@Components/loader/loader.component';
import { TournamentOverlayComp } from './overlay/tournament-overlay.component';
import { OverallStandingComp } from './overlay/overall-standing-section/overall-standing-section.component';
import { PlayoffSectionComp } from './overlay/playoff-section/playoff-section.component';
import { ConfrontationComp } from './overlay/playoff-section/confrontation/confrontation.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-tournaments', comp: TournamentsComp },
    { tag: 'app-tournament-card', comp: TournamentCardComp },
    { tag: 'app-loader', comp: LoaderComp },
    { tag: 'app-tournament-overlay', comp: TournamentOverlayComp },
    { tag: 'app-overall-standing', comp: OverallStandingComp },
    { tag: 'app-playoff-section', comp: PlayoffSectionComp },
    { tag: 'app-confrontation', comp: ConfrontationComp }
];
