import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class TournamentsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!TournamentsHttp.#instance)
            TournamentsHttp.#instance = new TournamentsHttp();
        return TournamentsHttp.#instance;
    }
    async getAll() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getAll', err);
            throw err;
        }
    }
    async getAllBySeasons() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAllBySeasons`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getAll', err);
            throw err;
        }
    }
    async getTournamentsBySeasonsAndStatus(status) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAllBySeasonsAndStatus?status=${status}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getTournamentsBySeasonsAndStatus', err);
            throw err;
        }
    }
}
