// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-players-component__host{display:block;height:100%;padding:1rem}`, "",{"version":3,"sources":["webpack://./admin-page/admin-players/admin-players.component.scss"],"names":[],"mappings":"AAAA,+BACI,aAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".host {\n    display: block;\n    height: 100%;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `admin-players-component__host`
};
module.exports = ___CSS_LOADER_EXPORT___;
