export const NAV_LINKS = [
    {
        path: '/',
        name: 'Главная'
    },
    {
        path: '/tournaments',
        name: 'Турниры'
    },
    {
        path: '/statistics',
        name: 'Статистика'
    },
    {
        path: '/news',
        name: 'Новости'
    },
    {
        path: '/calendar',
        name: 'Календарь'
    },
    {
        path: '/documents',
        name: 'Документы'
    },
    {
        path: '/contacts',
        name: 'Контакты'
    },
    {
        path: '/about',
        name: 'О лиге'
    },
    {
        path: '/admin',
        name: 'Админка'
    }
];
