import css from './create-document.component.scss';
export class CreateDocumentComp extends HTMLElement {
    titleRef;
    documentRef;
    saveButtonRef;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkForm(), this.mkButtonsSection());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkForm() {
        const form = document.createElement('form');
        form.id = 'createDocumentForm';
        form.classList.add(css.form);
        form.append(this.mkDocumentNameLabel(), this.mkNewDocumentButtonLabel());
        form.addEventListener('change', () => {
            if (this.documentRef.value && this.titleRef.value) {
                this.saveButtonRef.disabled = false;
            }
            else {
                this.saveButtonRef.disabled = true;
            }
        });
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.dispatchEvent(new CustomEvent('add', {
                detail: {
                    name: this.titleRef.value,
                    type: this.documentRef.files[0].type.split('/')[1],
                    file: this.documentRef.value
                }
            }));
        });
        return form;
    }
    mkDocumentNameLabel() {
        const documentNameLabel = document.createElement('label');
        documentNameLabel.classList.add(css.documentNameLabel);
        documentNameLabel.textContent = 'Имя файла для пользователя';
        documentNameLabel.append(this.mkDocumentName());
        return documentNameLabel;
    }
    mkDocumentName() {
        const documentName = document.createElement('input');
        this.titleRef = documentName;
        documentName.classList.add(css.documentName);
        documentName.placeholder = 'Введите имя файла для пользователя';
        documentName.type = 'text';
        documentName.required = true;
        return documentName;
    }
    mkNewDocumentButtonLabel() {
        const newDocumentButtonLabel = document.createElement('label');
        newDocumentButtonLabel.classList.add(css.newDocumentButtonLabel);
        newDocumentButtonLabel.textContent = 'Файл';
        newDocumentButtonLabel.append(this.mkNewDocumentButton());
        return newDocumentButtonLabel;
    }
    mkNewDocumentButton() {
        const button = document.createElement('input');
        this.documentRef = button;
        button.classList.add(css.newDocumentInput);
        button.type = 'file';
        button.required = true;
        button.accept = '.docx, .pdf';
        return button;
    }
    mkButtonsSection() {
        const buttonsSection = document.createElement('div');
        buttonsSection.classList.add(css.buttonsSection);
        buttonsSection.append(this.mkCancelButton(), this.mkSaveButton());
        return buttonsSection;
    }
    mkSaveButton() {
        const saveButton = document.createElement('button');
        this.saveButtonRef = saveButton;
        saveButton.type = 'submit';
        saveButton.disabled = true;
        saveButton.setAttribute('form', 'createDocumentForm');
        saveButton.classList.add(css.saveButton);
        saveButton.textContent = 'Готово';
        return saveButton;
    }
    mkCancelButton() {
        const cancelButton = document.createElement('button');
        cancelButton.type = 'reset';
        cancelButton.classList.add(css.cancelButton);
        cancelButton.textContent = 'Отменить';
        cancelButton.addEventListener('click', () => {
            this.remove();
            this.dispatchEvent(new CustomEvent('closed'));
        });
        return cancelButton;
    }
}
