import { DefaultSection } from '@Components/section/section.component';
import css from './news-section.component.scss';
import { Subject } from 'rxjs';
export class NewsSectionComp extends DefaultSection {
    news = new Subject();
    subscriptions = [];
    currentUpdateOverlayRef = null;
    set newsSetter(news) {
        this.news.next(news);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Новости'), this.mkHeaderUnderline(), this.mkNews());
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkNews() {
        const newsCont = document.createElement('div');
        newsCont.classList.add(css.newsCont);
        const newsSubscription = this.news.subscribe(news => {
            newsCont.replaceChildren(!news
                ? this.mkLoader()
                : news.length
                    ? this.mkNewsList(news)
                    : this.mkEmptyPlaceholder());
        });
        this.subscriptions.push(newsSubscription);
        newsCont.append(this.mkLoader());
        return newsCont;
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
    mkNewsList(news) {
        const list = document.createElement('ul');
        list.classList.add(css.newsList);
        news.forEach(el => {
            const li = document.createElement('li');
            li.append(this.mkNewsPreview(el));
            list.append(li);
        });
        return list;
    }
    mkNewsPreview(news) {
        const newsPreview = document.createElement('app-news-preview');
        newsPreview.turnOffRoutingSetter = true;
        newsPreview.classList.add(css.newsPreview);
        newsPreview.newsSetter = news;
        newsPreview.addEventListener('click', () => {
            const initialNews = news;
            this.openNewsEditor(initialNews);
        });
        return newsPreview;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    openNewsEditor(initialNews) {
        if (this.currentUpdateOverlayRef)
            return;
        const overlay = this.mkUpdateOverlay(initialNews);
        this.append(overlay);
        setTimeout(() => overlay.classList.add('isOpened'));
        this.currentUpdateOverlayRef = overlay;
    }
    mkUpdateOverlay(initialNews) {
        const overlay = document.createElement('app-news-editor');
        overlay.initialNewsSetter = initialNews;
        overlay.addEventListener('closed', () => this.currentUpdateOverlayRef = null);
        return overlay;
    }
}
