// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page-component__host{display:flex;height:100vh;width:100%}.admin-page-component__routerOutlet{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./admin-page/admin-page.component.scss"],"names":[],"mappings":"AAAA,4BACI,YAAA,CACA,YAAA,CACA,UAAA,CAGJ,oCACI,UAAA","sourcesContent":[".host {\n    display: flex;\n    height: 100vh;\n    width: 100%;\n}\n\n.routerOutlet {\n    flex: 1 1 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `admin-page-component__host`,
	"routerOutlet": `admin-page-component__routerOutlet`
};
module.exports = ___CSS_LOADER_EXPORT___;
