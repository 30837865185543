import { isCompRoute } from '@Helpers/router.helper';
export class Router {
    host;
    routes;
    static NOT_FOUND_ROUTE = {
        path: /not found/,
        view: 'app-404'
    };
    constructor(host, routes) {
        this.host = host;
        this.routes = routes;
    }
    generateOutlet() {
        if (this.host.hasChildNodes())
            this.clearOutlet();
        const routeMatch = this.findMatch();
        if (isCompRoute(routeMatch)) {
            this.host.append(document.createElement(routeMatch.view));
            return;
        }
    }
    clearOutlet() {
        this.host.removeChild(this.host.firstChild);
    }
    findMatch() {
        return this.routes.find(route => route.path.test(location.pathname))
            || Router.NOT_FOUND_ROUTE;
    }
}
const rootRoutes = [
    {
        path: /^\/$/,
        view: 'app-main',
    },
    {
        path: /^\/tournaments\/?/,
        view: 'app-tournaments'
    },
    {
        path: /^\/statistics\/?/,
        view: 'app-statistics'
    },
    {
        path: /^\/news-details\/[a-zA-Z0-9]{24}\/?/,
        view: 'app-news-details-page'
    },
    {
        path: /^\/news\/?/,
        view: 'app-news-page'
    },
    {
        path: /^\/calendar\/?/,
        view: 'app-calendar'
    },
    {
        path: /^\/documents\/?/,
        view: 'app-documents-page'
    },
    {
        path: /^\/contacts\/?/,
        view: 'app-contacts-page'
    },
    {
        path: /^\/about\/?/,
        view: 'app-about-page'
    },
    {
        path: /^\/login\/?/,
        view: 'app-login'
    },
    {
        path: /^\/admin\/?/,
        view: 'app-admin-page'
    },
    {
        path: /^\/player\/[a-zA-Z0-9]{24}\/?/,
        view: 'app-player-page'
    },
    {
        path: /^\/match\/[a-zA-Z0-9]{24}\/?/,
        view: 'app-match-page'
    }
];
export const rootRouter = new Router(document.getElementById('app'), rootRoutes);
