import css from './tournament-settings.component.scss';
export class TournamentSettingsComp extends HTMLElement {
    tournament;
    tournamentType;
    set tournamentSetter(tournament) {
        this.tournament = tournament;
    }
    set tournamentTypeSetter(tournamentType) {
        this.tournamentType = tournamentType;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkForm());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkForm() {
        const form = document.createElement('form');
        form.classList.add(css.form);
        form.append(this.mkGeneralSettings(), this.mkAdvancedSettings(), this.mkSaveBtn());
        form.addEventListener('submit', e => {
            e.preventDefault();
            // TODO: добавить генерирование FormData и выполнить запрос редактирования/создания
        });
        return form;
    }
    mkGeneralSettings() {
        const settings = document.createElement('app-tournament-general-settings');
        settings.tournamentSetter = this.tournament;
        return settings;
    }
    mkAdvancedSettings() {
        return this.tournamentType === 'regular'
            ? this.mkRegularSettings()
            : this.mkStrongestBattleSettings();
    }
    mkRegularSettings() {
        const settings = document.createElement('app-tournament-regular-settings');
        settings.tournamentStatsSetter = this.tournament.regular;
        return settings;
    }
    mkStrongestBattleSettings() {
        const settings = document.createElement('app-tournament-strongest-battle-settings');
        settings.tournamentStatsSetter = this.tournament.strongestBattle;
        return settings;
    }
    mkSaveBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.saveBtn);
        btn.textContent = 'Сохранить';
        return btn;
    }
}
