// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-details-page-component__host{display:block}.news-details-page-component__paddingContent{display:flex;padding:2rem 10vw;flex-direction:column;min-height:95vh;gap:1rem}.news-details-page-component__loaderCont{display:flex;align-items:center;justify-content:center;flex-grow:1}@media(max-width: 599px){.news-details-page-component__paddingContent{padding:2rem 5vw}}@media(min-width: 600px)and (max-width: 960px){.news-details-page-component__paddingContent{padding:2rem 5vw}}`, "",{"version":3,"sources":["webpack://./news-details-page/news-details-page.component.scss"],"names":[],"mappings":"AAAA,mCACI,aAAA,CAGJ,6CACI,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA,CACA,QAAA,CAGJ,yCACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAGJ,yBACI,6CACI,gBAAA,CAAA,CAIR,+CACI,6CACI,gBAAA,CAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 10vw;\n    flex-direction: column;\n    min-height: 95vh;\n    gap: 1rem;\n}\n\n.loaderCont {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-grow: 1;\n}\n\n@media (max-width: 599px) {\n    .paddingContent {\n        padding: 2rem 5vw;\n    }\n}\n\n@media (min-width: 600px) and (max-width: 960px) {\n    .paddingContent {\n        padding: 2rem 5vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `news-details-page-component__host`,
	"paddingContent": `news-details-page-component__paddingContent`,
	"loaderCont": `news-details-page-component__loaderCont`
};
module.exports = ___CSS_LOADER_EXPORT___;
