import { NewsComp } from './news-page.component';
import { FooterComp } from '@Components/footer/footer.component';
import { NewsSliderSectionComp } from './news-slider-section/news-slider-section.component';
import { NewsMiniPreviewComp } from '@Components/news-mini-preview/news-mini-preview.component';
import { SliderComp } from '@Components/slider/slider.component';
import { LoaderComp } from '@Components/loader/loader.component';
import { NewsFeedSectionComp } from './news-feed-section/news-feed-section.component';
import { SponsorsComp } from '@Components/sponsors/sponsors.component';
import NewsPreviewComp from '@Components/news-preview/news-preview.component';
import { SubnavComp } from '@Components/subnav/subnav.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-news-page', comp: NewsComp },
    { tag: 'app-footer', comp: FooterComp },
    { tag: 'app-news-slider-section', comp: NewsSliderSectionComp },
    { tag: 'app-news-mini-preview', comp: NewsMiniPreviewComp },
    { tag: 'app-news-preview', comp: NewsPreviewComp },
    { tag: 'app-slider', comp: SliderComp },
    { tag: 'app-loader', comp: LoaderComp },
    { tag: 'app-news-feed-section', comp: NewsFeedSectionComp },
    { tag: 'app-sponsors', comp: SponsorsComp },
    { tag: 'app-subnav', comp: SubnavComp }
];
