import css from './news-editor.component.scss';
import { NewsHttp } from '@Services/http/news.http';
import { Subject } from 'rxjs';
export class NewsEditorComp extends HTMLElement {
    contentEditorRef;
    formRef;
    loaderRef;
    saveBtnRef;
    photoInputRef;
    subscriptions = [];
    newsHttp = NewsHttp.instance;
    isLoading = new Subject();
    photoValidityChange = new Subject();
    UPLOAD_PHOTO_SIZE_LIMIT_BYTES = 350000;
    initialNews;
    constructor() {
        super();
    }
    get isEditMode() {
        return !!this.initialNews;
    }
    set initialNewsSetter(news) {
        this.initialNews = news;
    }
    connectedCallback() {
        this.render();
        this.escBtnListener();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkCloseBtn(), this.mkForm());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkForm() {
        const form = document.createElement('form');
        this.formRef = form;
        form.classList.add(css.form);
        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            if (this.isEditMode) {
                this.updateNews();
                return;
            }
            this.createNews();
        });
        form.append(this.mkCategoryEditor(), this.mkTitleEditor(), this.mkLeadEditor(), this.mkPhotoEditor(), this.mkContentEditor(), this.mkFormBtns());
        return form;
    }
    mkCloseBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.closeBtn);
        btn.append(this.mkCloseIcon());
        btn.addEventListener('click', () => this.closeEditor());
        return btn;
    }
    mkCloseIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.closeIcon);
        return icon;
    }
    mkTitleEditor() {
        const label = document.createElement('label');
        label.classList.add(css.titleEditor);
        label.textContent = 'Заголовок*';
        label.append(this.mkTitleInput());
        return label;
    }
    mkTitleInput() {
        const input = document.createElement('input');
        input.classList.add(css.titleInput);
        input.required = true;
        input.name = 'title';
        if (this.initialNews)
            input.value = this.initialNews.title;
        return input;
    }
    mkCategoryEditor() {
        const label = document.createElement('label');
        label.classList.add(css.categoryEditor);
        label.textContent = 'Категория*';
        label.append(this.mkCategorySelector());
        return label;
    }
    mkCategorySelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.required = true;
        selector.name = 'category';
        selector.append(this.mkSelectorOption('players', 'игроки'), this.mkSelectorOption('tournaments', 'турниры'), this.mkSelectorOption('judging', 'судейство'), this.mkSelectorOption('others', 'другое'));
        if (this.initialNews)
            selector.value = this.initialNews.category;
        return selector;
    }
    mkSelectorOption(value, text) {
        const option = document.createElement('option');
        option.value = value;
        option.textContent = text;
        return option;
    }
    mkPhotoEditor() {
        const photoEditor = document.createElement('div');
        photoEditor.classList.add(css.photoEditor);
        photoEditor.append(this.mkPhotoEditorLabel(), this.mkPhotoInput(), this.mkPhotoPreview());
        return photoEditor;
    }
    mkPhotoInput() {
        const input = document.createElement('input');
        input.classList.add(css.chooseFileBtn);
        this.photoInputRef = input;
        input.type = 'file';
        input.accept = 'image/*';
        input.name = 'img';
        input.required = !this.isEditMode;
        input.addEventListener('change', () => {
            if (!input.files)
                return;
            if (input.files[0].size > this.UPLOAD_PHOTO_SIZE_LIMIT_BYTES) {
                this.photoValidityChange.next(false);
                input.value = '';
                return;
            }
            this.photoValidityChange.next(true);
        });
        return input;
    }
    mkPhotoPreview() {
        const preview = document.createElement('div');
        preview.classList.add(css.photoPreview);
        this.photoValidityChange.subscribe(isValid => preview.replaceChildren(isValid
            ? this.mkPhoto(URL.createObjectURL(this.photoInputRef.files[0]))
            : this.mkUploadPhotoError()));
        if (this.initialNews) {
            preview.append(this.mkPhoto(`/news-assets/${this.initialNews.img}`));
        }
        return preview;
    }
    mkUploadPhotoError() {
        const errMsg = document.createElement('p');
        errMsg.classList.add(css.errMsg);
        errMsg.textContent = `Размер файла превышает ${this.UPLOAD_PHOTO_SIZE_LIMIT_BYTES} байт. Попробуйте уменьшить размер фото или конвертируйте в webp/jpg`;
        return errMsg;
    }
    mkPhoto(photoPath) {
        const photo = document.createElement('img');
        photo.classList.add(css.photo);
        photo.src = photoPath;
        return photo;
    }
    mkPhotoEditorLabel() {
        const label = document.createElement('label');
        label.classList.add(css.photoEditorLabel);
        label.textContent = 'Фотография*';
        return label;
    }
    mkLeadEditor() {
        const label = document.createElement('label');
        label.classList.add(css.leadEditor);
        label.textContent = 'Лид*';
        label.append(this.mkLeadTextArea());
        return label;
    }
    mkLeadTextArea() {
        const textarea = document.createElement('textarea');
        textarea.classList.add(css.leadTextarea);
        textarea.rows = 4;
        textarea.required = true;
        textarea.name = 'lead';
        if (this.initialNews)
            textarea.value = this.initialNews.lead;
        return textarea;
    }
    mkContentEditor() {
        const content = document.createElement('div');
        content.classList.add(css.contentEditor);
        content.append(this.mkContentEditorLabel(), this.mkEditor());
        return content;
    }
    mkContentEditorLabel() {
        const label = document.createElement('label');
        label.classList.add(css.contentEditorLabel);
        label.textContent = 'Содержимое*';
        return label;
    }
    mkEditor() {
        const editor = document.createElement('app-editorjs');
        this.contentEditorRef = editor;
        editor.classList.add(css.editorJs);
        if (this.initialNews) {
            editor.dataSetter = JSON.parse(this.initialNews.content);
        }
        return editor;
    }
    mkFormBtns() {
        const btns = document.createElement('div');
        btns.classList.add(css.formBtns);
        const loadingSubscription = this.isLoading.subscribe(isLoading => {
            if (isLoading) {
                this.saveBtnRef.disabled = true;
                btns.append(this.mkLoader());
                return;
            }
            this.saveBtnRef.disabled = false;
            this.loaderRef.remove();
        });
        this.subscriptions.push(loadingSubscription);
        btns.append(this.mkCancelBtn(), this.mkSaveBtn());
        return btns;
    }
    mkCancelBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.cancelBtn);
        btn.textContent = 'Отменить';
        btn.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('closed'));
            this.remove();
        });
        return btn;
    }
    mkSaveBtn() {
        const btn = document.createElement('button');
        this.saveBtnRef = btn;
        btn.classList.add(css.saveBtn);
        btn.textContent = 'Сохранить';
        btn.type = 'button';
        btn.addEventListener('click', () => this.checkContentValidity());
        return btn;
    }
    mkLoader() {
        const loader = document.createElement('app-loader');
        this.loaderRef = loader;
        return loader;
    }
    checkContentValidity() {
        this.contentEditorRef.save()
            .then(data => {
            if (!data.blocks.length) {
                this.contentEditorRef.classList.add(css.redBorder);
                setTimeout(() => this.contentEditorRef.classList.remove(css.redBorder), 2000);
                return;
            }
            this.formRef.requestSubmit();
        })
            .catch();
    }
    async createNews() {
        try {
            this.isLoading.next(true);
            const createdNews = await this.composeNewNewsBody();
            this.newsHttp.postNews(createdNews)
                .then(() => {
                this.isLoading.next(false);
                location.reload();
            })
                .catch();
        }
        catch (err) {
            console.log('[ADMIN NEWS PAGE] error during createNews: ', err);
        }
    }
    async updateNews() {
        try {
            this.isLoading.next(true);
            const body = await this.composeUpdatedNewsBody();
            for (const [name, value] of body) {
                console.log('name', name, 'value', value);
            }
            this.newsHttp.updateNews(this.initialNews._id, body)
                .then(() => {
                this.isLoading.next(false);
                location.reload();
            })
                .catch();
        }
        catch (err) {
            console.log('[ADMIN NEWS PAGE] error during updateNews: ', err);
        }
    }
    async composeNewNewsBody() {
        const contentEditorData = JSON.stringify(await this.contentEditorRef.save());
        const formData = new FormData(this.formRef);
        formData.set('postedDate', new Date().toISOString());
        formData.set('content', contentEditorData);
        return formData;
    }
    async composeUpdatedNewsBody() {
        const contentEditorData = JSON.stringify(await this.contentEditorRef.save());
        const formData = new FormData(this.formRef);
        formData.set('postedDate', new Date().toISOString());
        formData.set('content', contentEditorData);
        if (!this.photoInputRef.value)
            formData.delete('img');
        return formData;
    }
    closeEditor() {
        this.dispatchEvent(new CustomEvent('closed'));
        this.remove();
    }
    escBtnListener() {
        this.addEventListener('keyup', (e) => {
            if (e.code === 'Escape')
                this.closeEditor();
        });
    }
}
