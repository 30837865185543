// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confrontation-component__host{display:flex;flex-direction:column;padding:.325rem .5rem;gap:.5rem}.confrontation-component__team{display:flex;align-items:center;gap:.5rem}.confrontation-component__logo{width:2rem;height:2rem;border-radius:50%;object-fit:cover}.confrontation-component__participant{display:flex;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./tournaments-page/overlay/playoff-section/confrontation/confrontation.component.scss"],"names":[],"mappings":"AAAA,+BACI,YAAA,CACA,qBAAA,CACA,qBAAA,CACA,SAAA,CAGJ,+BACI,YAAA,CACA,kBAAA,CACA,SAAA,CAGJ,+BACI,UAAA,CACA,WAAA,CACA,iBAAA,CACA,gBAAA,CAGJ,sCACI,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".host {\n    display: flex;\n    flex-direction: column;\n    padding: 0.325rem 0.5rem;\n    gap: 0.5rem;\n}\n\n.team {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.logo {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 50%;\n    object-fit: cover;\n}\n\n.participant {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `confrontation-component__host`,
	"team": `confrontation-component__team`,
	"logo": `confrontation-component__logo`,
	"participant": `confrontation-component__participant`
};
module.exports = ___CSS_LOADER_EXPORT___;
